<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}



.alert-primary {
    width: 100%;
}
</style>

<template>
    <div>

        <!-- Form Modal -->
        <b-modal ref="myModalPcn" centered no-close-on-backdrop no-close-on-esc :hide-footer=true size="lg">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>PCN Tickets</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="PrinterIcon" size="16" @click="print()" />
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>


            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">SN</th>
                            <th scope="col">Barcode</th>
                            <th scope="col">NGrade</th>
                            <th scope="col">HGrade</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tiket, index)  in tikets">
                            <th scope="row">{{ index+1 }}</th>
                            <td>{{ tiket.barcode }}</td>
                            <td>{{ tiket.grade }}</td>
                            <td>{{ tiket.houseGrade }}</td>
                            <td>{{ tiket.mass }}</td>
                            <td>{{ tiket.value.toFixed(3) }}</td>
                        </tr>

                    </tbody>

                    <tfoot>
                        <tr style="font-weight:bold;">
                            <td colspan="4" class="footer">&nbsp;</td>
                            <td class="footer">{{ totalWeight.toFixed(3) }}</td>
                            <td class="footer">{{ tValue.toFixed(3) }} </td>
                        </tr>
                    </tfoot>
                </table>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>

                <b-row v-if="role.id == 5">
                    <b-col cols="3">
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="filter.region_id"
                                    @change="changeRegion(filter.region_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locations" :key="location.id"
                                        :value="location">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                    serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="society_id" label="Society">
                            <validation-provider #default="{ errors }" name="Society" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="effectiveDate" label="Efective Date">
                            <validation-provider #default="{ errors }" name="Efective Date" rules="">
                                <b-form-datepicker id="effectiveDate" :min="minDate" name="effectiveDate"
                                    v-model="filter.effectiveDate" :state="errors.length > 0 ? false : null" size="md"
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    locale="en" />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.effectiveDate">{{
                                    serverErrors.effectiveDate[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">

                        <div class="mt-10" style="margin-top: 35px">
                            <b-button variant="primary" size="sm" @click="getPcn()">
                                <span class="text-nowrap">Add Effective Date</span>
                            </b-button>
                        </div>

                    </b-col>
                </b-row>

                <b-row v-if="role.id != 5">
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="society_id" label="Society">
                            <validation-provider #default="{ errors }" name="Society" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="effectiveDate" label="Efective Date">
                            <validation-provider #default="{ errors }" name="Efective Date" rules="">
                                <b-form-datepicker id="effectiveDate" :min="minDate" name="effectiveDate"
                                    v-model="filter.effectiveDate" :state="errors.length > 0 ? false : null" size="md"
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    locale="en" />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.effectiveDate">{{
                                    serverErrors.effectiveDate[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">

                        <div class="mt-10" style="margin-top: 35px">
                            <b-button variant="primary" size="sm" @click="getPcn()">
                                <span class="text-nowrap">Add Effective Date</span>
                            </b-button>
                        </div>

                    </b-col>
                </b-row>

                <b-row>

                    <!-- <pre>
                    {{ filter }}
                 </pre> -->
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <b-col cols="12">
                        <b-card no-body>
                            <b-card-header class="pb-50">
                                <h5>UPDATE PURCHASE CONTRACT OF MARKET SALE</h5>
                            </b-card-header>
                            <b-card-body>
                                <h6>Market: </h6>
                                <h6>Society: </h6>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />

                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">
                <template #cell(value)="data">
                    <!-- {{ getTotalValue(data.item.tickets) }} -->
                </template>
                <template #cell(mass)="data">
                    <!-- {{ getTotalMass(data.item.tickets) }} -->
                </template>



                <!-- Column: Actions -->


                <template #cell(actions)="data">

                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <b-form-checkbox class="custom-control-success ml-4" name="check-button" switch
                            @change="handleCheckboxChange($event, data.item.id)" :checked="data.item.received">
                            <span class="switch-icon-left">
                                <feather-icon icon="CheckCircleIcon" />
                            </span>
                        </b-form-checkbox>

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="viewPcn(data.item.tickets, data.item)">
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">View</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import receivePcnStoreModule from '@/views/cromis/sales/receive_pcn/receivePcnStoreModule'
import useReceivePcnsList from '@/views/cromis/sales/receive_pcn/useReceivePcnsList'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab, BFormDatepicker
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModalPcn = ref(null)
        const myViewModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const totalMass = ref(null)
        const totalValue = ref(null)
        const errosOcurs = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const totalWeight = ref(0)
        const tValue = ref(0)
        const companies = ref([])
        const societies = ref([])
        const markets = ref([])
        const requisitionDetail = ref(null)
        const locations = ref([])
        const sales = ref([])
        const children = ref([])
        const tikets = ref([])
        const fullPcn = ref([])
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        const requisition = ref({
            requisition_id: null,
            type: null,
            ranges: [
                {
                    start: null,
                    end: null,
                }
            ]
        })
        const filter = ref({
            region_id: null,
            location_id: null,
            market_id: null,
            sale_id: null,
            society_id: null,
            effectiveDate: null
        })



        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        const role = ref(JSON.parse(localStorage.getItem('userData')).roles[0])
        console.log(locId)





        const CROMIS_STORE_MODULE_NAME = 'cromis-receive'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, receivePcnStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {


            await store.dispatch('cromis-receive/locations')
                .then(response => {
                    locations.value = response.data.locations

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            resolveStatusVariant,
            resolveReceivedVariant,
            locationFilter,
            marketFilter,
            saleFilter,
            societyFilter
        } = useReceivePcnsList()

        // Form Methods
        const viewPcn = (item, pcn) => {
            fullPcn.value = pcn
            tikets.value = item

            for (let index = 0; index < tikets.value.length; index++) {
                console.log(tikets.value[index])
                totalWeight.value += parseFloat(tikets.value[index].mass);
                tValue.value += parseFloat(tikets.value[index].value);

            }

            myModalPcn.value.show()
        }

        const print = async () => {
            
            let data = {
                "society_id": fullPcn.value.society.id,
                "sale_id": fullPcn.value.sale.id,
                "print": true
            }
            console.log(data)

            await store.dispatch('cromis-receive/createPrint', data)
                .then(response => {
                   
                    context.root.$swal({
                        icon: 'success',
                        text: 'Sales Document prints initialized!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                   
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })

        }



        const approveRequisition = (item) => {
            console.log(item)
            handleApprove(item)
        }

        const getTotalValue = (valueArray) => {
            console.log(valueArray)
            totalValue.value = 0;
            for (let index = 0; index < valueArray.length; index++) {
                totalValue.value = valueArray[index].mass;

            }
            // valueArray.forEach(ele => {
            //     totalValue.value += ele.value;
            // });



            return totalValue.value;
        }

        const getTotalMass = (massArray) => {
            totalMass.value = 0;
            // console.log(massArray)
            // massArray.forEach(ele => {
            //     totalMass.value += ele.mass;
            // });

            for (let index = 0; index < massArray.length; index++) {
                totalMass.value = massArray[index].value;

            }

            return totalMass.value;
        }
        const invokeUpdateForm = (item) => {
            console.log(item)
        }

        const getPcn = async () => {
            filter.value.sale_id = filter.value.sale_id.id
            console.log(filter.value.sale_id)
            let data = {
                "effectiveDate": filter.value.effectiveDate,
                "society_id": filter.value.society_id,
                "sale_id": filter.value.sale_id,
            }
            console.log(data)


            await store.dispatch('cromis-receive/effectiveDate', data)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Effective date is associated has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleCheckboxChange = async (event, id) => {

            console.log(event)
            let payload = {
                "received": event
            }

            await store.dispatch('cromis-receive/update', { id: id, data: payload })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false


                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to PCN has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const getAreaMarket = async () => {
            locationFilter.value = filter.value.location_id
            await store.dispatch('cromis-receive/markets', { location_id: filter.value.location_id })
                .then(response => {
                    markets.value = response.data.markets

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getAreaMarketSale = async () => {
            marketFilter.value = filter.value.market_id
            await store.dispatch('cromis-receive/sales', { market_id: filter.value.market_id })
                .then(response => {
                    sales.value = response.data.sales
                    societies.value = response.data.sales.societies
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSociet = async (id) => {

            societyFilter.value = id
        }

        const changeSale = async (event) => {
            saleFilter.value = event.id
            societies.value = event.societies
            // filter.value.sale_id = event.id
        }

        const changeRegion = async (event) => {

            locId.value = event
            console.log(locId.value)
            locationFilter.value = event.id
            // filter.value.sale_id = event.id
        }




        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleCreate()
        }



        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }



        const handleApprove = async (item) => {

            saving.value = true

            let data = {
                "approved": true
            }

            console.log(item)
            console.log(data)

            await store.dispatch('cromis-receive/update', { id: item.id, data: data })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Approved has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }



        const remove = async (id) => {
            await store.dispatch('cromis-receive/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            requisition,

            selectedOwner: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: '-- Select Box Type --' },
                { value: '1', text: 'Bale Ticket' },
                { value: '2', text: 'Luggage' },
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            resolveReceivedVariant,

            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModalPcn,
            myViewModal,
            requisitionDetail,
            filter,
            markets,
            locations,
            sales,
            changeSale,
            changeSociet,
            changeRegion,
            myRangeModal,
            locationFilter,
            marketFilter,
            saleFilter,
            societyFilter,
            viewPcn,
            approveRequisition,
            submit,
            isFormValid,
            handleCreate,
            companies,
            societies,
            errosOcurs,
            locId,
            role,
            getAreaMarket,
            getAreaMarketSale,
            handleApprove,
            minDate,
            getPcn,
            handleCheckboxChange,
            getTotalMass,
            getTotalValue,
            totalMass,
            totalValue,
            tikets,
            totalWeight,
            tValue,
            invokeUpdateForm,
            fullPcn,
            print
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>